<template>
	<b-card title="List Service">
		<b-table 
			striped 
			hover 
			:items="services"
			:fields="fields"
			:busy="isLoading"
			show-empty
			responsive
		>
			<template #table-busy>
				<div class="text-center text-secondary my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>

			<template v-slot:cell(no)="{ index }">
				{{ index + 1 }}
			</template>

			<template v-slot:cell(status)="{ item }">
				<span
					class="badge badge-pill badge-success"
					v-if="item.status === 'active'"
				>
					Aktif
				</span>
				<span
					class="badge badge-pill badge-danger"
					v-if="item.status === 'inactive'"
				>
					Tidak Aktif
				</span>
				<span
					class="badge badge-pill badge-secondary"
					v-if="item.status === 'coming_soon'"
				>
					Segera Hadir
				</span>
				<span
					class="badge badge-pill badge-warning"
					v-if="item.status === 'maintenance'"
				>
					Maintenance
				</span>
			</template>

			<template v-slot:cell(actions)="{ item }">
				<div class="d-flex" style="gap:12px;">
					<b-badge 
						variant="warning"
						class="badge-glow"
					>
						<feather-icon
							:id="`invoice-row-${item.uuid}-edit-icon`"
							icon="EditIcon"
							class="cursor-pointer"
							size="16"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							v-b-tooltip.hover.bottom="'Edit Data'"
							@click="editItem(item)"
							v-if="checkPermission('update service')"
						/>
					</b-badge>

				</div>
			</template>
		</b-table>
		<b-modal
			id="form-user-modal"
			centered
			title="Service Info Detail"
			hide-footer
			no-close-on-backdrop
		>
			<ValidationObserver>
				<form @submit.prevent="updateItem(formPayload)">
					<div class="form-group">
						<label>Nama</label>
						<validation-provider
							name="Nama"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								type="text" 
								class="form-control" 
								:class="classes"
								:state="errors.length > 0 ? false:null"
								v-model="formPayload.name"
							>
							<span
								v-for="(validation, index) in validations.name"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</span>
						</validation-provider>
					</div>
					<div class="form-group">
						<label>Status</label>
						<select 
							class="form-control"
							v-model="formPayload.status"
						>
							<option value="">-- Pilih Status --</option>
							<option value="active">Aktif</option>
							<option value="inactive">Tidak Aktif</option>
							<option value="coming_soon">Segera Hadir</option>
							<option value="maintenance">Maintenance</option>
						</select>
					</div>
					<div class="form-group">
						<label>Harga</label>
						<validation-provider
							name="Harga"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								type="text" 
								class="form-control" 
								:class="classes"
								:state="errors.length > 0 ? false:null"
								v-model="formPayload.price"
							>
							<span
								v-for="(validation, index) in validations.price"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</span>
						</validation-provider>
					</div>
					<div class="form-group">
						<label>Qty</label>
						<validation-provider
							name="Qty"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								type="text" 
								class="form-control" 
								:class="classes"
								:state="errors.length > 0 ? false:null"
								v-model="formPayload.qty"
							>
							<span
								v-for="(validation, index) in validations.qty"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</span>
						</validation-provider>
					</div>
					<div class="form-group">
						<label>Description</label>
						<textarea 
							class="form-control"
							cols="30" rows="8"
							v-model="formPayload.description"
						/>
						<span
							v-for="(validation, index) in validations.description"
							:key="`errorName${index}`"
							class="text-danger"
						>{{ validation }}</span>
					</div>
					<button
						variant="primary"
						class="btn waves-effect waves-float waves-light btn-primary mt-2"
					>
						Save
					</button>
				</form>
			</ValidationObserver>
		</b-modal>
	</b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import _ from 'lodash'
import { 
	BCard,
	BBadge,
	BRow,
	BCol,
	BCardText,
	BTable,
	BPagination,
	BSpinner,
	VBModal,
	VBTooltip
} from 'bootstrap-vue'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BBadge,
		BRow,
		BCol,
		BCardText,
		BTable,
		BPagination,
		BSpinner,
		ValidationProvider, 
		ValidationObserver,
	},

	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	directives: {
	'b-tooltip': VBTooltip,
    'b-modal': VBModal,
		Ripple,
	},
	setup() {
		return {
			checkPermission,
			successNotification, 
			errorNotification
		}
	},
	data() {
		return {
			required,
			currentPage: 1,
			// result:{},
			isLoading: false,
			fields: ['no', 'name', 'price', 'status' ,'unit', 'qty' ,'actions'],
			services: [],
			filter: {
				keyword: ''
			},
			formService: {
				status:''
			},
			formPayload: Object,
			validations: ''
		}
	},

	computed: {
		rows() {
			return this.services.length
		}
	},

	methods: {

		cleanUpForm() {
			this.validations = ''
		},

		getData(page) {
			this.isLoading = true
			const queryParams = this.filter
			queryParams.per_page = 10
			queryParams.page = page
			this.$http.get('admin/services', {
				params: queryParams,
			}).then(response => {
				this.result = response.data.data
				this.services = response.data.data
				this.isLoading = false
			})
		},
		updateItem(item) {
			item._method = 'PATCH'
			this.$http.post('admin/services/' + item.uuid, item)
			.then(response => {
				this.getData()
				successNotification(this, 'Success', 'Service Berhasil Diedit!')
				this.$bvModal.hide('form-user-modal')
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Name', this.validations.name.join(' '))
					errorNotification(this, 'Aktivasi', this.validations.status.join(' '))
					errorNotification(this, 'Tipe Harga', this.validations.price_type.join(' '))
					errorNotification(this, 'Harga', this.validations.price.join(' '))
					errorNotification(this, 'Qty', this.validations.admin_price.join(' '))
					errorNotification(this, 'Deskripsi', this.validations.description.join(' '))
				}
			})
		},
		editItem(item) {
			this.cleanUpForm();
			this.formPayload = {
				uuid: item.uuid,
				name: item.name,
				status: item.status,
				price: item.price,
				qty: item.qty,
				description: item.description,
			}
			this.$bvModal.show('form-user-modal')
		},
	},

	created() {
		this.getData()
	}
}
</script>